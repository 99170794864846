import React from 'react';
import { Create, SimpleForm, TextInput, email, CheckboxGroupInput, BooleanInput } from 'react-admin';
import IconLock from '@material-ui/icons/Lock';

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" label="Email" validate={email()} />
      <TextInput source="password" type="password" />
      <CheckboxGroupInput source="roles" choices={[
        { id: 'ROLE_ADMIN', name: 'Administrateur' },
      ]} />
      <BooleanInput source="locked" options={{
        checkedIcon: <IconLock />,
      }} />
    </SimpleForm>
  </Create>
);
