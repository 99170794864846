import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Logo from './Logo';

const styles = theme => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

export default withStyles(styles)(({ title, logout, open, classes }) => (
  <AppBar title={title} logout={logout} open={open} classes={classes}>
    <Typography
      variant="title"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />
    <Logo style={{ margin: '10px' }} />
    <span style={{display: 'flex'}} />
  </AppBar>
));
