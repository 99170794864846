import React from 'react';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { Redirect, Route } from 'react-router-dom';

import { theme as customTheme } from '.';
import { fetchHeaders } from './dataProvider';
import { cleanAuthLocalStorage } from './authProvider';

export default entrypoint => parseHydraDocumentation(`${entrypoint}`, { headers: new Headers(fetchHeaders()) })
.then(
  ({ api }) => {

    api.resources.map(resource => {
      if ( typeof customTheme.icons[resource.name] != 'undefined' ) {
        resource.icon = customTheme.icons[resource.name];
      }
      return resource;
    });

    return Promise.resolve({ api, customRoutes: [], });

  },
  (result) => {
    switch (result.status) {
      case 401:
        cleanAuthLocalStorage();
        window.location.reload();
        return Promise.resolve({
          api: result.api,
          customRoutes: [
            <Route path="/" render={() => <Redirect to={`/login`}/>} />,
          ],
        });

      default:
        return Promise.reject(result);
    }
  },
).catch(error => {
  cleanAuthLocalStorage();
  window.location.reload();
  return Promise.resolve({
    api: { resources: [], entrypoint },
    customRoutes: [
      <Route path="/" render={() => <Redirect to={`/login`}/>} />,
    ],
  });
});
