export default {
  globalError: 'Unable to contact API, please try again later...',
  'Bad Request': 'Request Error',
  'Internal Server Error': 'Server Error',
  'Unauthorized': 'Unauthorized',
  roles: {
    'READER': 'Reader',
    'ADMIN': 'Administrator',
    'SUPER_ADMIN': 'Super-Admin',
    'USER': 'User',
  },
  helpPage: {
    menu: 'Aide',
    url: 'https://xd.adobe.com/embed/8f58a42d-a7e1-40c7-70d7-4299c387afea-f969/?hints=off',
  },
  edit: 'Edit',
  error: 'Error',
  summary: 'Summary',
  preview: 'Preview',
  created: 'Created',
  updated: 'Updated',
  view: 'View',
  author: 'Author',
  details: 'Details',
  loading: 'Loading...',
  ra: {
    action: {
      select: 'Select',
      send: 'Send',
      open: 'Open',
      close: 'Close',
      read: 'Read',
      previous: 'Previous',
      next: 'Next',
      editRegions: 'Regions',
      showPages: 'Pages',
      backToList: 'Back to list',
    },
  },
  resources: {
    brands: {
      name: 'Brand |||| Brands',
      fields: {
        name: 'Name',
        issues: 'Issues',
      },
      notifications: {
        current_changed_failure: 'Error while moving to brand...',
      },
      editGuide: {
        infosTitle: 'Informations',
        newsletterTitle: 'Newsletter',
        newsletterCaption: [
          'Possible :',
          '- dans le SSO (1 ou plusieurs clés à mettre à 1)',
          '- via Mailchimp (2 valeurs à saisir, 1 - API Key / 2 - List ID)',
          '- Mise à jour d\'un champ Clé / Valeur sur le SSO, spécifique à la marque',
        ],
        ssoTitle: 'Connexion SSO',
        ssoLoginTitle: 'Connexion non-admins',
        ssoOffersTitle: 'Offres d\'essai',
        ssoCaption: [
          'SSO Rules : 1 ou plusieurs règles à vérifier pour valider la connexion utilisateur (seulement pour les non-admins)',
          'Offres d\'essai :',
          '1. On vérifie si la connexion échoue la ou les règles ssoRulesFailure',
          '2. Si ça échoue, on vérifie la règle ssoRulesTryOfferEnable',
          '3. Si ça passe, on met à jour les champs Dates suivants, espacées du nombre de jours spécifié, puis on retente le 1.'
        ],
      },
    },
    users: {
      name: 'User |||| Users',
      fields: {
        email: 'Mail address',
        username: 'Username',
        brands: 'Linked brands',
      }
    },
    issues: {
      name: 'Issue |||| Issues',
      fields: {
        cover: 'Cover',
        name: 'Name',
        description: 'Description',
        brand: 'Brand',
        number: 'Number',
        isTemplate: 'Template ?',
        helpPage: 'Help Page',
        pages: 'Pages',
        publicationDate: 'Publication Date',
        isPublic: 'Published ?',
        isFree: 'Free ?',
        dates: 'Update date',
      },
      buttons: {
        addPage: 'Add a page',
        bulkIsPublic: 'Publish',
        bulkIsNotPublic: 'Unpublish',
      },
      actions: {
        bulkSetPublic: 'Publish these issues ?',
        bulkSetPublicConfirm: 'Are you sure to publish these issues ?',
        bulkSetNotPublic: 'Unpublish these issues ?',
        bulkSetNotPublicConfirm: 'Are you sure to unpûblish these issues ?',
        saveAndRedirect: 'Create and edit',
        saveAndAdd: 'Create and add',
      }
    },
    pages: {
      name: 'Page |||| Pages',
      emptyBackground: 'Select background first...',
      fields: {
        name: 'Name',
        issue: 'Issue',
        position: 'Order',
        status: 'Status',
        regions: 'Regions',
        background: 'Background',
      },
      statuses: {
        new: 'New',
        draft: 'Draft',
        approval: 'Needs validation',
        public: 'OK',
        null: '-',
      }
    },
    media_objects: {
      name: 'Media |||| Medias',
      uploaded: '%{filename} sent (%{id})',
      uploadedMultiple: '%{count} filles sent',
      uploadedEmpty: 'No file sent',
      selectModal: 'Select a file',
      selectModalMultiple: 'Select one or many files',
      folders: {
        all: 'All folders',
      },
      fields: {
        name: 'Name',
        directory: 'Directory',
        contentUrl: 'Content URL',
      },
      buttons: {
        upload: 'Send files',
      },
    },
    regions: {
      fields: {
        type: 'Type',
        media: 'Picture',
        medias: 'Pictures',
      },
      emptyEdit: 'Select a region...',
      settings: 'Settings',
      types: {
        label: 'Interaction Type',
        empty: 'Choose a type...',
        nav: {
          label: 'Navigation',
          page: {
            label: 'Linked Page',
            empty: 'Choose a page...',
            previous: 'Previous',
            next: 'Next',
            createFirst: 'Create another page first',
          },
        },
        modal: {
          label: 'Simple Lightbox',
          backdropExit: {
            label: 'Enable exiting on whole screen click',
          },
          backdropColor: {
            label: 'Backdrop color',
            helper: '100% transparent possible',
          },
        },
        iframe: {
          label: 'Iframe Lightbox',
          backdropExit: {
            label: 'Enable exiting on whole screen click',
          },
          backdropColor: {
            label: 'Backdrop color',
            helper: '100% transparent possible',
          },
          code: {
            label: 'Iframe code',
            helper: '<iframe /> code from Youtube, Soundcloud, etc...',
          },
          sizes: {
            label: 'Dimensions',
            width: 'Ratio Width',
            height: 'Ratio Height',
            maxWidth: 'Width max. (px)',
          },
        },
        tooltip: {
          label: 'Tooltip',
        },
        slideshow: {
          label: 'Fullscreen slideshow',
          backdropExit: {
            label: 'Enable exiting on whole screen click',
          },
          backdropColor: {
            label: 'Backdrop color',
            helper: '100% transparent possible',
          },
        },
      }
    }
  }
};
