import jwtDecode from 'jwt-decode';

// Change this to be your own login check route.
const login_uri = process.env.REACT_APP_API_ENTRYPOINT + '/login';

export const LOCAL_STORAGE_KEY_TOKEN = 'pilou-et-fleurette--admin--token';
export const LOCAL_STORAGE_KEY_ROLES = 'pilou-et-fleurette--admin--roles';
export const LOCAL_STORAGE_KEY_EXPIRES = 'pilou-et-fleurette--admin--expires';
export const LOCAL_STORAGE_KEY_IDENTITY = 'pilou-et-fleurette--admin--identity';

export const getAuthRoles = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY_ROLES) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_ROLES)) : [];
};

export const cleanAuthLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY_ROLES);
  localStorage.removeItem(LOCAL_STORAGE_KEY_EXPIRES);
  localStorage.removeItem(LOCAL_STORAGE_KEY_IDENTITY);
};

export default {
  login: params => {
    const { username, password } = params;
    const request = new Request(`${login_uri}`, {
      method: 'POST',
      body: JSON.stringify({ username, password, admin: true }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, token);
        const decodedToken = jwtDecode(token);
        console.log(decodedToken);
        localStorage.setItem(LOCAL_STORAGE_KEY_ROLES, JSON.stringify(decodedToken.roles));
        localStorage.setItem(LOCAL_STORAGE_KEY_EXPIRES, decodedToken.exp);
        localStorage.setItem(LOCAL_STORAGE_KEY_IDENTITY, JSON.stringify({ id: '', fullname: decodedToken.username, avatar: null }));
        window.location.replace(process.env.PUBLIC_URL);
      });
  },
  logout: params => {
    cleanAuthLocalStorage();
    return Promise.resolve();
  },
  checkAuth: params => {
    console.log('auth check', params, localStorage.getItem(LOCAL_STORAGE_KEY_EXPIRES), Math.floor(Date.now() / 1000));
    return localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) && localStorage.getItem(LOCAL_STORAGE_KEY_EXPIRES) && localStorage.getItem(LOCAL_STORAGE_KEY_EXPIRES) > Math.floor(Date.now() / 1000) ? Promise.resolve() : Promise.reject();
  },
  checkError: error => {
    console.log('check error', error);
    if (401 === error.response.status || 403 === error.response.status) {
      cleanAuthLocalStorage();
      // window.location.reload();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: params => {
    const roles = getAuthRoles();
    const permissions = roles ? { roles, rights: {} } : false;
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  },
  getIdentity: () => {
    const { id, fullName, avatar } = localStorage.getItem(LOCAL_STORAGE_KEY_IDENTITY) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_IDENTITY)) : { id: '', fullName: 'Non connecté', avatar: null };
    return { id, fullName, avatar };
  },
};
