export default {
  globalError: 'Impossible de contacter l\'API, merci de réessayer ultérieurement.',
  'Bad Request': 'Erreur dans la requête',
  'Internal Server Error': 'Erreur serveur',
  'Unauthorized': 'Non autorisé',
  roles: {
    'READER': 'Abonné',
    'ADMIN': 'Administrateur',
    'SUPER_ADMIN': 'Super-Admin',
    'USER': 'Utilisateur',
  },
  helpPage: {
    menu: 'Aide',
    url: 'https://xd.adobe.com/embed/49ac4bee-22fb-4f1b-682a-f63b90ae49ba-3f9d/?hints=off',
  },
  edit: 'Modifier',
  error: 'Erreur',
  summary: 'Résumé',
  preview: 'Aperçu',
  created: 'Créé',
  updated: 'Mis à jour',
  view: 'Voir',
  author: 'Auteur',
  details: 'Détails',
  loading: 'Chargement...',
  ra: {
    action: {
      select: 'Sélectionner',
      send: 'Envoyer',
      open: 'Ouvrir',
      close: 'Fermer',
      read: 'Lire',
      previous: 'Précédent',
      next: 'Suivant',
      editRegions: 'Régions',
      showPages: 'Pages',
      backToList: 'Retour à la liste',
    },
  },
  resources: {
    users: {
      name: 'Utilisateur |||| Utilisateurs',
      fields: {
        email: 'Adresse mail',
        locked: 'Bloqué ?',
        username: 'Nom d\'utilisateur',
        created: 'Date de création du compte',
        last_login: 'Dernière visite',
        visit_count: 'Nbre de connexions',
      }
    },
  }
};
