import { createMuiTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import {
  SupervisorAccount as IconUsers,
} from '@material-ui/icons';

export const muiTheme = createMuiTheme({
  palette: {
    primary: green,
    secondary: grey,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [ '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif', ].join(','),
  },
  overrides: {
    MuiButton: {
      // root: { color: 'white', },
    },
  },
});

export const icons = {
  users: IconUsers,
};
