import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { mergeTranslations, resolveBrowserLocale } from 'react-admin';

import domainMessagesFr from './fr';
import domainMessagesEn from './en';

import * as momentJs from 'moment';
import 'moment/locale/fr';
momentJs.locale('fr');
export const moment = momentJs;

export const allMessages = {
  fr: mergeTranslations ( frenchMessages, domainMessagesFr ),
  en: mergeTranslations ( englishMessages, domainMessagesEn ),
};

const ensureLocale = (locale) => typeof allMessages[locale] != 'undefined' ? locale : 'fr';

let currentLocale = ensureLocale(resolveBrowserLocale());

export default polyglotI18nProvider(locale => allMessages[ensureLocale(locale)], currentLocale);
