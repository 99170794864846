import React from 'react';
import { Edit, SimpleForm, TextInput, CheckboxGroupInput, NumberInput, BooleanInput } from 'react-admin';
import IconLock from '@material-ui/icons/Lock';

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="originId" label="ID" />
      <TextInput disabled source="email" />
      <TextInput source="password" type="password" />
      <CheckboxGroupInput source="roles" choices={[
        { id: 'ROLE_ADMIN', name: 'Administrateur' },
      ]} />
      <NumberInput source="visit_count" />
      <BooleanInput source="locked" options={{
        checkedIcon: <IconLock />,
      }} />
    </SimpleForm>
  </Edit>
);
