import React from 'react';
import { List, Datagrid, TextField, EmailField, DateField, FunctionField, EditButton, translate, Filter, TextInput, BooleanField } from 'react-admin';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const RolesField = translate(({ record, translate }) => (
  <div>
    {record.roles.map(item => (
      item !== 'ROLE_USER' ? <span key={item} className="chip">{translate('roles.' + item.replace('ROLE_', ''))}</span> : null
    ))}
  </div>
));

const PostFilter = (props) => (
  <Filter {...props}>
      <TextInput label="Adresse email" source="email" alwaysOn />
  </Filter>
);

export default translate((props) => (
  <List {...props} filters={<PostFilter />} perPage={30}>
    <Datagrid>
      <TextField source="id" sortable={false} />
      <EmailField source="email" />
      <RolesField source="roles" sortable={false} />
      <DateField source="created" showTime />
      <DateField source="last_login" showTime />
      <BooleanField source="locked" TrueIcon={LockIcon} FalseIcon={LockOpenIcon} valueLabelTrue="Utilisateur bloqué" valueLabelFalse="Utilisateur valide" />
      <FunctionField label="Nombre de connexions" sortBy="visit_count" render={record => `${record.visit_count} connexion${record.visit_count > 1 ? 's' : ''}`} />
      <EditButton />
    </Datagrid>
  </List>
));

