import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
} from '@api-platform/admin';
import { LOCAL_STORAGE_KEY_TOKEN/*, LOCAL_STORAGE_KEY_ROLES, getAuthRoles*/ } from './authProvider';

export const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

export const fetchHeaders = () => {
    // console.log( LOCAL_STORAGE_KEY_TOKEN, window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) );
    // console.log( LOCAL_STORAGE_KEY_ROLES, getAuthRoles() );
    // return {'Authorization': `Bearer ${window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) ? window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) : 'not-logged'}`};
    return window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) ? {'Authorization': `Bearer ${window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN)}`} : {};
};

export const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders()),
});

export default (api, apiDocumentationParser) => baseHydraDataProvider(api.entrypoint, fetchHydra, apiDocumentationParser);
