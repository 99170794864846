import React, { Component, Fragment } from 'react';
import { Resource, Loading, Login } from 'react-admin';
import { HydraAdmin } from "@api-platform/admin";
import sanitizeRestProps from 'ra-ui-materialui/lib/input/sanitizeRestProps';

import {
  authProvider,
  apiDocumentationParser,
  theme as customTheme,
  i18nProvider,
} from './Services';
import dataProvider, { entrypoint } from './Services/dataProvider';

import CustomLayout from './Components/Layout/Layout';

import {
  List as UserList,
  Edit as UserEdit,
  Create as UserCreate,
} from './Components/User';

import './App.css';

const LogoCenter = () => <div style={{ textAlign: 'center' }}><img src={`${process.env.PUBLIC_URL}/favicon.png`} alt="Logo" style={{ maxWidth: '90px' }} /></div>;

export default class extends Component {
  state = {
    api: null,
    customRoutes: [],
    hasError: false,
    loaded: false,
  };

  componentDidMount() {
    apiDocumentationParser(entrypoint)
      .then(
        ({api, customRoutes}) => ({
          api,
          customRoutes,
          hasError: false,
          loaded: true,
        }),
        data => {
          if (data instanceof Error) console.error('apiDocumentationParser error', data);
          return {
            api: data.api,
            customRoutes: data.customRoutes,
            hasError: true,
            loaded: true,
          };
        },
      )
      .then(this.setState.bind(this));
  }

  render() {
    const { api, customRoutes } = this.state;

    if (false === this.state.loaded) return <div style={{ display: 'flex', background: '#f1f1f1', height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'fixed', top: 0, left: 0 }}>
      <Loading loadingPrimary={<Fragment><LogoCenter />{i18nProvider.translate('ra.page.loading')}</Fragment>} loadingSecondary={i18nProvider.translate('ra.message.loading')} />
    </div>;

    const resources = api.resources; // .filter(({deprecated}) => !deprecated);
    const users = resources.find((element) => element.name === 'users');
    const dataProviderReady = dataProvider(api, apiDocumentationParser);
    return (
      <HydraAdmin api={ api } title={ api.title }
        loginPage={ ({ staticContext, ...props }) => <Login {...sanitizeRestProps(props)} backgroundImage="https://images.unsplash.com/photo-1528402190200-d53f2729901f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1980&q=80" /> }
        apiDocumentationParser={ apiDocumentationParser }
        entrypoint= { entrypoint }
        dataProvider= { dataProviderReady }
        authProvider={ authProvider }
        customRoutes={ customRoutes ? customRoutes : [] }
        i18nProvider={ i18nProvider }
        theme={ customTheme.muiTheme }
        appLayout={ CustomLayout }
      >
        {true === this.state.hasError || !users ? <span className="error">{i18nProvider.translate('globalError')}</span>
        : [
          <Resource
              key={ users.name }
              options={{ resource: users }}
              name={ users.name }
              icon={ users.icon }
              list={ UserList }
              edit={ UserEdit }
              create={ UserCreate }
            />,
        ]}
      </HydraAdmin>
    )
  }
}

